import { IRefreshTokenResponse } from '@/graphql/user'
import { SETTINGS } from '@/constants/settings'
import { UserModule } from '@/store/modules/user'
import UserApi from '@/api/userApi'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'root',
    redirect: '/dashboard/index',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard',
    redirect: '/dashboard/index',
    meta: {
      requiresAuth: true
    },
    component: async () => await import(
      /* webpackChunkName: "page-dashboard" */
      '@/views/Dashboard.vue'
    ),
    children: [
      {
        path: 'index',
        name: 'dashboard',
        component: async () => await import(
          /* webpackChunkName: "page-admin-index" */
          '@/views/admin/IndexView.vue'
        )
      },
      {
        path: 'datasets',
        name: 'Datasets',
        props: { dataModel: 'dataset' },
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-datasets" */
          '@/views/admin/ListDataModelPage.vue'
        )
      },
      {
        path: 'datasets/dataset/',
        name: 'Add Dataset',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        props: (route) => ({ dataModel: 'dataset' }),
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-add-datamodel" */
          '@/views/admin/AddDataModelPage.vue'
        )
      },
      {
        path: 'datasets/dataset/:id',
        name: 'Edit Dataset',
        props: (route) => ({
          dataModel: 'dataset',
          id: route.params.id
        }),
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-edit-datamodel" */
          '@/views/admin/EditDataModelPage.vue'
        )
      },
      {
        path: 'collections',
        name: 'Collections',
        props: { dataModel: 'collection' },
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-list-datamodel" */
          '@/views/admin/ListDataModelPage.vue'
        )
      },
      {
        path: 'collections/collection/:id',
        name: 'Edit Collection',
        props: (route) => ({
          dataModel: 'collection',
          id: route.params.id
        }),
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-edit-datamodel" */
          '@/views/admin/EditDataModelPage.vue'
        )
      },
      {
        path: 'collections/collection',
        name: 'Add Collection',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        props: (route) => ({ dataModel: 'collection' }),
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-add-datamodel" */
          '@/views/admin/AddDataModelPage.vue'
        )
      },
      {
        path: 'files',
        name: 'Files',
        props: { dataModel: 'file' },
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-datasets" */
          '@/views/admin/ListDataModelPage.vue'
        )
      },
      {
        path: 'files/file/',
        name: 'Add File',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        props: (route) => ({ dataModel: 'file' }),
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-add-datamodel" */
          '@/views/admin/AddDataModelPage.vue'
        )
      },
      {
        path: 'files/file/:id',
        name: 'Edit File',
        props: (route) => ({
          dataModel: 'file',
          id: route.params.id
        }),
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-edit-datamodel" */
          '@/views/admin/EditDataModelPage.vue'
        )
      },
      {
        path: 'charts',
        name: 'Charts',
        props: { dataModel: 'chart' },
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-datasets" */
          '@/views/admin/ListDataModelPage.vue'
        )
      },
      {
        path: 'charts/chart/:id',
        name: 'Edit Chart',
        props: (route) => ({
          dataModel: 'chart',
          id: route.params.id
        }),
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-edit-datamodel" */
          '@/views/admin/EditDataModelPage.vue'
        )
      },
      {
        path: 'charts/chart',
        name: 'Add Chart',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        props: (route) => ({ dataModel: 'chart' }),
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-edit-datamodel" */
          '@/views/admin/AddDataModelPage.vue'
        )
      },
      {
        path: 'shapes',
        name: 'Shapes',
        props: { dataModel: 'shape' },
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-datasets" */
          '@/views/admin/ListDataModelPage.vue'
        )
      },
      {
        path: 'shapes/shape/',
        name: 'Add Shape',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        props: (route) => ({ dataModel: 'shape' }),
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-add-datamodel" */
          '@/views/admin/AddDataModelPage.vue'
        )
      },
      {
        path: 'shapes/shape/:id',
        name: 'Edit Shape',
        props: (route) => ({
          dataModel: 'shape',
          id: route.params.id
        }),
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-edit-datamodel" */
          '@/views/admin/EditDataModelPage.vue'
        )
      },
      {
        path: 'themes',
        name: 'Themes',
        props: { dataModel: 'theme' },
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-datasets" */
          '@/views/admin/ListDataModelPage.vue'
        )
      },
      {
        path: 'themes/theme/',
        name: 'Add Theme',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        props: (route) => ({ dataModel: 'theme' }),
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-add-datamodel" */
          '@/views/admin/AddDataModelPage.vue'
        )
      },
      {
        path: 'themes/theme/:id',
        name: 'Edit Theme',
        props: (route) => ({
          dataModel: 'theme',
          id: route.params.id
        }),
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-edit-datamodel" */
          '@/views/admin/EditDataModelPage.vue'
        )
      },
      {
        path: 'tags',
        name: 'list_tags',
        props: { dataModel: 'tag' },
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-datasets" */
          '@/views/admin/ListDataModelPage.vue'
        )
      },
      {
        path: 'tags/tag/',
        name: 'add_tag',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        props: (route) => ({ dataModel: 'tag' }),
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-add-datamodel" */
          '@/views/admin/AddDataModelPage.vue'
        )
      },
      {
        path: 'tags/tag/:id',
        name: 'edit_tag',
        props: (route) => ({
          dataModel: 'tag',
          id: route.params.id
        }),
        meta: {
          requiresAuth: true
        },
        component: async () => await import(
          /* webpackChunkName: "page-edit-datamodel" */
          '@/views/admin/EditDataModelPage.vue'
        )
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: async () => await import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: {
      requiresAuth: false
    }
  },
  // catch all
  {
    path: '*',
    component: async () => await import(
      /* webpackChunkName: "page-not-found" */
      '@/views/PageNotFound.vue'
    )
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/* function trailingSlash(str: string) {
  return str.endsWith('/') ? str : str + '/'
} */

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {

    let isVerified: boolean = await UserApi.verifyAccessToken(localStorage.getItem(SETTINGS.AUTH.TOKEN_NAME))
    // try to refresh token once
    if (!isVerified) {
      const refreshResult: IRefreshTokenResponse | null = await UserApi.refreshAccessToken()
      if (refreshResult?.success) {
        // try to get user info
        isVerified = await UserModule.loginUserWithToken()
      }
    }
    if (isVerified && UserModule.authStatus) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})
export default router
