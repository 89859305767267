import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import store from '@/store'

export interface INotification {
  type: 'error' | 'success';
  message: string;
  show?: boolean;
  sticky?: boolean;
}

export interface INotificaationState {
  notifications: INotification[];
}

@Module({
  dynamic: true,
  store,
  name: 'notification'
})
class Notification extends VuexModule implements INotificaationState {

  public notifications: INotification[] = []

  @Action
  public showNotification(notification: INotification) {
    notification.show = true
    this._notification(notification)
  }

  @Action
  public removeNotification(notification: INotification) {
    this._removeNotification()
  }

  @Mutation
  private _notification(value: INotification) {
    // sdf
    this.notifications = this.notifications.concat(value)
  }

  @Mutation
  private _removeNotification() {
    this.notifications = this.notifications.filter(item => item.show)
  }
}

export const NotificationModule = getModule(Notification)
