import { UserModule } from '@/store/modules/user'
import NamedLogger from '@/utils/NamedLogger'

export type PermissionType = 'view' | 'add' | 'change' | 'delete'

const logger: NamedLogger = NamedLogger.getLogger('UserPermissionCheck')
export default class UserPermissionCheck {

  static hasPermission(dataModel: string, permissionType: PermissionType): boolean {

    // check user has permission
    const permissionToCheck: string = 'main.' + permissionType.toString() + '_' + dataModel
    const hasPermission: boolean = UserModule.user.permissions.includes(permissionToCheck)
    // logger.log('hasPermission ', '"' + permissionToCheck + '"', hasPermission)
    return hasPermission
  }

}
