export default function statusToLabel(status: string): string {
  const label: {
    A_0: string;
    A_1: string;
    A_2: string;
    A_3: string;
  } = {
    A_0: 'gelöscht',
    A_1: 'privat',
    A_2: 'intern',
    A_3: 'public'
  }
  // switch is a bit overkill - but TS interfered with return label[status]
  switch (status) {
    case 'A_0':
      return label.A_0
    case 'A_1':
      return label.A_1
    case 'A_2':
      return label.A_2
    case 'A_3':
      return label.A_3
  }
  return 'not found'
}
