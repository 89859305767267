export class LogUtil {

  public static log (...args: any) {
    // eslint-disable-next-line prefer-spread
    console.log.apply(console, args)
  }

  public static error (...args: any) {
    // eslint-disable-next-line prefer-spread
    console.error.apply(console, args)

  }
}
