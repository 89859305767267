import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#00a6de',
        secondary: '#673ab7',
        accent: '#009688',
        error: '#e91e63',
        warning: '#f44336',
        info: '#00bcd4',
        success: '#4caf50'
      }
    }
  }

})
