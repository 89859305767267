import gql from 'graphql-tag'

export interface IUserNode {
  id: string;
  isActive: boolean;
  username: string;
  permissions: string[];
  email: string;
}

export interface ILoginUserResponse {

  success: boolean;
  errors: string;
  token: string;
  refreshToken: string;
  user: IUserNode;

}

export interface IRefreshTokenResponse {

  success: boolean;
  errors: string;
  token: string;
  refreshToken: string;

}

const UserFragment = gql`
fragment UserFragment on UserNode {
    id
    email
    username
    isActive
    permissions
}
`

export const LOGIN_USER = gql`
mutation tokenAuth($username: String!, $password: String!) {
  tokenAuth(username: $username, password: $password) {
      success
      errors
      token
      refreshToken
      user {
       ...UserFragment
    }
  }
}
${UserFragment}
`

export const REFRESH_TOKEN = gql`
mutation refreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
      success
      errors
      token
      refreshToken
  }
}
`

/*
user {
       ...UserFragment
    }
 */

export interface ILoggedInUserResponse {
  me: IUserNode;
}

export const LOGGED_IN_USER = gql`
  query {
    me {
      ...UserFragment
    }
  }
${UserFragment}
`
