import UserPermissionCheck, { PermissionType } from '@/utils/UserPermissionCheck'
import _Vue from 'vue'

// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export function PermissionPlugin(Vue: typeof _Vue, options?: any): void {
  Vue.prototype.$hasPermission = (dataModel: string, type: PermissionType) => {
    return UserPermissionCheck.hasPermission(dataModel, type)
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $hasPermission: typeof UserPermissionCheck.hasPermission;
  }
}
