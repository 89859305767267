
import { UserModule } from '@/store/modules/user'
import Component from 'vue-class-component'
import NotificationBar from '@/components/NotificationBar.vue'
import Vue from 'vue'
import router from '@/router'
import store from '@/store'

@Component(
  {
    name: 'App',
    components: {
      NotificationBar,
    }
  }
)
export default class App extends Vue {
  private mounted() {
    if (UserModule.authStatus) {
      // this.showHome()
    } else {
      console.log('mountde, show login')
      this.showLogin()
    }
  }

  private created() {
    console.log('user auth stauts', UserModule.authStatus)
    const unsubscribe = store.subscribe((mutation, state) => {
      // console.log('mutation type', mutation.type)
      // console.log('mutation payload', mutation.payload)
      if (mutation.type === 'setUserDetails') {
        console.log('auth status changed -> navigate')
        if (mutation.payload) {
          // this.showHome()
        } else {
          this.showLogin()
        }
      }
    })
  }

  private showHome() {
    console.log('show home')
    void router.push({ path: '/' })
  }

  private showLogin() {
    console.log('show login')
    void router.push({ name: 'Login' })
  }

}
