import { DateTimeFormatOptions } from 'vue-i18n'

export default function toListDate(date: string) {

  const dateFormatOptions: DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }
  const d = new Date(date)
  return d.toLocaleString('de-DE', dateFormatOptions)
}
