import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import { apolloClient } from '@/plugins/vue-apollo'
import UserApi from '@/api/userApi'
import store from '@/store'

import { ILoggedInUserResponse, ILoginUserResponse, IUserNode } from '@/graphql/user'

export interface IUserState {
  token: string;
  user: IUserNode;
}

@Module({
  dynamic: true,
  store,
  name: 'user'
})
export default class UserStore extends VuexModule implements IUserState {
  public token: string = ''

  public user: IUserNode = {
    email: '',
    id: '',
    permissions: [],
    isActive: false,
    username: ''
  }

  public get authStatus(): boolean {

    return this.user?.isActive
  }

  @Mutation
  setToken(value: string) {
    this.token = value
  }

  @Mutation
  setUserDetails(user: IUserNode | null) {
    // reset user
    if (user === null) {
      this.user = {
        email: '',
        id: '',
        isActive: false,
        permissions: [],
        username: ''
      }
    } else {
      this.user.isActive = user.isActive
      this.user.username = user.username
      this.user.permissions = user.permissions
      this.user.id = user.id
      this.user.email = user.email
    }
  }

  @Action({ commit: 'setUserDetails' })
  public async login(userData: { username: string; password: string; }) {
    console.log('login action')
    let {
      username,
      password
    } = userData
    username = username.trim()
    const data: ILoginUserResponse = await UserApi.login(username, password)
    if (data?.success) {
      console.log('login success')
      this.setToken(data.token)
    }
    console.log('login result', data)
    return data.user
  }

  @Action
  public async logout() {
    console.log('logout action')
    const data = await UserApi.logout()
    // clear grapql cache
    await apolloClient.cache.reset()
    this.setUserDetails(null)
    this.setToken('')
  }

  @Action
  public async loginUserWithToken() {
    const data: ILoggedInUserResponse | null = await UserApi.whoami()
    if (data?.me) {
      console.log(data)
      this.setUserDetails(data.me)
      return true
    }
    return false

  }

}
export const UserModule = getModule(UserStore)
