import '@/styles/_main.scss'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { PermissionPlugin } from '@/plugins/PermissionCheck'
import { addTokenToUrl } from '@/utils/URLHelper'
import { apolloClient, createProvider } from './plugins/vue-apollo'
import { startUpCommand } from '@/commands/startUpCommand'
import { sync } from 'vuex-router-sync'
import App from './App.vue'
import Component from 'vue-class-component'
import Vue, { provide } from 'vue'
import i18n from './plugins/i18n'
import pluralize from '@/filter/pluralize'
import router from './router'
import slugify from '@/filter/slugify'
import statusToLabel from '@/filter/statusToLabel'
import store from './store'
import toListDate from '@/filter/toListDate'
import vuetify from './plugins/vuetify'

Vue.filter('toListDate', toListDate)
Vue.filter('statusLabel', statusToLabel)
Vue.filter('pluralize', pluralize)
Vue.filter('slugify', slugify)
Vue.filter('addToken', addTokenToUrl)

// class-Component Hooks

// Register the router hooks with their names
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate' // for vue-router 2.2+
])

Vue.use(PermissionPlugin)

Vue.config.productionTip = false
sync(store, router)

async function startup() {
  await startUpCommand()

  new Vue({
    setup () {
      provide(DefaultApolloClient, apolloClient)
    },
    router,
    store,
    vuetify,

    // apollo
    apolloProvider: createProvider(),

    i18n,
    render: h => h(App)
  }).$mount('#app')
}

void startup()
