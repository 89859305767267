import * as jose from 'jose'
import {
  ILoggedInUserResponse,
  ILoginUserResponse,
  IRefreshTokenResponse,
  LOGGED_IN_USER,
  LOGIN_USER,
  REFRESH_TOKEN
} from '@/graphql/user'
import { SETTINGS } from '@/constants/settings'
import { apolloClient, onLogin, onLogout } from '@/plugins/vue-apollo'
import NamedLogger from '@/utils/NamedLogger'

const logger: NamedLogger = NamedLogger.getLogger('UserAPI')

export default class UserApi {
  static async login(username: string, password: string): Promise<ILoginUserResponse> {
    console.log('login api try login with', username, password)
    const { data } = await apolloClient.mutate({
      mutation: LOGIN_USER,
      variables: {
        username,
        password
      }
    })

    if (data?.tokenAuth?.success) {
      console.log('set auth token', data.tokenAuth.token)
      await onLogin(apolloClient, data.tokenAuth.token, data.tokenAuth.refreshToken)
    } else {
      // pass
    }
    console.log('api login', data)
    return data.tokenAuth
  }

  static async logout() {
    return await onLogout(apolloClient)
  }

  static async whoami(): Promise<ILoggedInUserResponse | null> {
    try {
      const {
        data,
        errors
      } = await apolloClient.query({
        query: LOGGED_IN_USER
      })
      console.log('the errors', errors)
      return data
    } catch (e) {
      console.log('error happend', e)
      return null
    }

  }

  public static async verifyUserLoginState(): Promise<boolean> {

    const accessToken: string | null = localStorage.getItem(SETTINGS.AUTH.TOKEN_NAME)
    return await UserApi.verifyAccessToken(accessToken)
  }

  static async verifyAccessToken(accessToken: string | null = null): Promise<boolean> {
    if (accessToken === null) {
      accessToken = localStorage.getItem(SETTINGS.AUTH.TOKEN_NAME)
      if (accessToken === null) {
        return false
      }
    }
    const alg = 'RS256'
    const spki: string = SETTINGS.AUTH.PUBLIC_KEY as string
    try {
      const publicKey = await jose.importSPKI(spki, alg)
      const verifyResult = await jose.jwtVerify(accessToken, publicKey)
      logger.log('SUCCESS TOKEN VERIFICATION', verifyResult)
      if (verifyResult?.payload?.exp) {
        const now: number = new Date().getTime()
        const expirationTimeStamp: number = verifyResult.payload.exp * 1000
        const diff: number = expirationTimeStamp - now
        // TODO: 333, we could use the diff parameter to refresh the token a few minutes before it will be invalid
        logger.log('expires at', verifyResult.payload.exp, new Date(verifyResult.payload.exp * 1000), diff)
        return true
      }
    } catch (e) {
      logger.error('failed import/verify key', e)
    }
    return false
  }

  static async refreshAccessToken(refreshToken: string | null = null): Promise<IRefreshTokenResponse | null> {
    if (!refreshToken) {
      // default token
      refreshToken = localStorage.getItem('jwt-refresh-token')
    }

    if (!refreshToken) {
      return null
    }

    const { data } = await apolloClient.mutate({
      mutation: REFRESH_TOKEN,
      variables: {
        refreshToken
      }
    })

    if (data?.refreshToken?.success) {
      // store tokens, update apollo header
      await onLogin(apolloClient, data.refreshToken.token, data.refreshToken.refreshToken)
    } else {
      // clear all tokens
      await onLogout(apolloClient)
    }

    return data.refreshToken
  }

}
