import { LogUtil } from './LogUtil'

export default class NamedLogger {

  private readonly componentName: string

  constructor (componentName: string) {
    this.componentName = componentName
  }

  public static getLogger (name: string, disabled = false): NamedLogger {

    const logger: NamedLogger = new NamedLogger(name)
    if (disabled || process.env.NODE_ENV === 'production') {
      logger.log = () => {
        // do nothing;
      }
      logger.error = () => {
        // do nothing;
      }
    }
    return logger
  }

  public log (...args: any) {
    args.unshift(`[${this.componentName}] `)
    LogUtil.log(...args)
  }

  public error (...args: any) {
    args.unshift(`[${this.componentName}] `)
    LogUtil.error(...args)
  }

}
