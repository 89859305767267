// if this is not sophisticated enough - install https://github.com/vuejs-community/vue-filter-pluralize
export default function pluralize(count: number, options: string[]) {
  if (count === 0) {
    return options[1]
  } else if (count === 1) {
    return options[0]
  } else {
    return options[1]
  }
}
