import { SETTINGS } from '@/constants/settings'
import { UserModule } from '@/store/modules/user'
import NamedLogger from '@/utils/NamedLogger'
import UserApi from '@/api/userApi'

async function checkUserLoginState(): Promise<boolean> {

  const logger: NamedLogger = NamedLogger.getLogger('checkUserLoginState')
  let validUser = false

  // 0. does a access_token exists
  const accessToken = localStorage.getItem(SETTINGS.AUTH.TOKEN_NAME)

  logger.log('0. access Token exist', (accessToken) !== undefined)
  if (accessToken !== undefined) {

    // 2. check if the user is login / if we have a valid access_token
    const isValid: boolean = await UserApi.verifyUserLoginState()
    logger.log('1. is user verifyed', isValid)
    if (isValid) {
      validUser = true
    } else {

      logger.log('2. try to refresh the access_token')
      // try to fetch a new one with the refresh Token
      const refreshData = await UserApi.refreshAccessToken()
      if (refreshData) {
        // validate again
        const isValid2ndTry = await UserApi.verifyUserLoginState()
        logger.log('3. is user verifyed', isValid2ndTry)
        validUser = isValid2ndTry
      } else {
        validUser = false
      }

    }

  } else {
    validUser = false
  }

  logger.log('is valid', validUser)
  return validUser

}

export async function startUpCommand(): Promise<void> {

  const logger: NamedLogger = NamedLogger.getLogger('startUpCommand')

  // 1. check user login
  const userLoggedIn: boolean = await checkUserLoginState()
  logger.log('is user Logged In?', userLoggedIn)

  if (userLoggedIn) {
    await UserModule.loginUserWithToken()
  } else {
    await UserModule.logout()
  }
}
