import { ApolloClient, ApolloLink, InMemoryCache, Observable, ServerError, createHttpLink } from '@apollo/client/core'
import { SETTINGS } from '@/constants/settings'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error'
import NamedLogger from '@/utils/NamedLogger'
import UserApi from '@/api/userApi'
import Vue from 'vue'
import VueApollo from 'vue-apollo'

const logger: NamedLogger = NamedLogger.getLogger('VueApollo')

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
const AUTH_TOKEN = SETTINGS.AUTH.TOKEN_NAME

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP

// add authentication header
const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  // logger.log('auth middleware set headers')
  operation.setContext({
    headers: {
      authorization: `JWT ${localStorage.getItem(AUTH_TOKEN) ?? ''}`
    }
  })
  return forward(operation)
})

// default link
const defaultHttpLink = createHttpLink({
  uri: httpEndpoint,
  // headers: getHeaders(),
  fetch: async (...pl) => {
    // if (!DEBUG) return fetch(...pl)
    try {
      const [_, options] = pl
      // const body = JSON.parse(options.body.toString())
      // logger.log(``${body.operationName` || ''}\n${body.query}`, body.variables, body)
      // logger.log(`${body.operationName}`, options)
    } catch (e) {
      // pass
    }
    return await fetch(...pl)
  }
})

// link needed for uploads
const uploadLink = createUploadLink({
  uri: httpEndpoint,
  fetch: async (...pl: RequestInfo[]) => {
    // if (!DEBUG) return fetch(...pl)

    try {
      const [_, options] = pl
      // const body = JSON.parse(options.body)

      // logger.log(``${body.operationName` || ''}\n${body.query}`, body.variables, body)
      // logger.log(`UPLOAD ${options.body}`, options)
    } catch (e) {
      // pass
      logger.log('error upload log', e)
    }

    // @ts-expect-error
    return await fetch(...pl)
  }
})

// default link, decide to use uploadLink or defaultHttpLink by context parameter
const httpLink = ApolloLink.split(
  operation => operation.getContext().hasUpload,
  uploadLink,
  defaultHttpLink
)

// error handler
const errorLink = onError(({
  graphQLErrors,
  networkError,
  operation,
  forward,
  response
}) => {
  if (graphQLErrors) {
    const sessionTimedOut: boolean =
      graphQLErrors.findIndex(item => {
        return item.extensions && item.extensions.code === 440
      }) >= 0
    logger.log('sessionTimedOut? ', sessionTimedOut)
    if (sessionTimedOut) {
      // Obeservable - hacky stuff, because onError link does not support aysnc calls
      // https://stackoverflow.com/questions/50965347/how-to-execute-an-async-fetch-request-and-then-retry-last-failed-request/51321068#51321068
      return new Observable(observer => {
        UserApi.refreshAccessToken(
          localStorage.getItem(SETTINGS.AUTH.REFRESH_TOKEN) as string
        )
          .then(result => {
            logger.log('refresh token result', result)
            if (result?.success) {
              const oldHeaders = operation.getContext().headers
              // modify the operation context with a new token
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `JWT ${result.token}`
                }
              })
            } else {
              // could not refresh the access token -> back to login
              logger.log('could not refresh access_token!')
              localStorage.removeItem(AUTH_TOKEN)
              localStorage.removeItem('jwt-refresh-token')
              // reload whole page
              location.reload()
            }
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            }

            // Retry last failed request
            forward(operation).subscribe(subscriber)
          })
          .catch(error => {
            // No refresh or client token available, we force user to login
            observer.error(error)
          })
      })
    }

    graphQLErrors.forEach(
      ({ message, locations, path, originalError, extensions }) =>
        logger.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, originalError: ${originalError}`
        )
    )
  }

  if (networkError) {
    // Add something like this to set the error message to the one from the server response
    // throw new Error('' + (networkError as ServerError).result)
    logger.error('error status code', (networkError as ServerError).statusCode)
    logger.error(`[Network error]: ${networkError}`)
  }

})

// logger
const logLink = new ApolloLink((operation, forward) => {
  console.time(operation.operationName)
  return forward(operation).map(result => {
    console.timeEnd(operation.operationName)
    // console.info('logl ink response', operation.getContext())
    // logger.log('QUERY', operation.operationName, '<br>RESULT:', JSON.stringify(result.data, null, 4))
    return result
  })
})

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: undefined,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,
  // configure the link
  apollo: {
    link: authMiddleware.concat(logLink).concat(errorLink).concat(httpLink),
    cache: new InMemoryCache({
      addTypename: true
    })
  }
}

// Create apollo client
export const apolloClient = new ApolloClient({
  ssrMode: false,
  link: authMiddleware
    .concat(logLink)
    .concat(errorLink)
    .concat(httpLink) as any,
  cache: new InMemoryCache({ addTypename: true }) as any
})

// Call this in the Vue app file
export function createProvider() {
  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network'
      }
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    }
  })

  return apolloProvider
}

// Manually call this when user log in
export async function onLogin(apolloClient: any, token: string, refreshToken: string) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token)
    localStorage.setItem('jwt-refresh-token', refreshToken)
  }
  try {
    await apolloClient.resetStore()
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient: any) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN)
    localStorage.removeItem(SETTINGS.AUTH.REFRESH_TOKEN)
  }
  try {
    await apolloClient.resetStore()
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}
