

import { INotification, NotificationModule } from '@/store/modules/notification'
import Component from 'vue-class-component'
import Vue from 'vue'

@Component({})
export default class NotificationBar extends Vue {
  private get notifiations(): INotification[] {
    return NotificationModule.notifications
  }

  private onChangeHandler(item: any) {
    NotificationModule.removeNotification(item)
  }

  private onCloseHandler(notification: INotification) {
    notification.show = false
    NotificationModule.removeNotification(notification)
  }

}

