import Vue from 'vue'
import Vuex from 'vuex'

import { IAppState } from './modules/app'
import { INotification } from './modules/notification'
import { IUserState } from './modules/user'
import pathify from 'vuex-pathify'

// Modules
// import * as modules from './modules'

Vue.use(Vuex)

export interface IRootState {
  app: IAppState;
  user: IUserState;
  notification: INotification;
}

export default new Vuex.Store<IRootState>({
  plugins: [
    pathify.plugin
  ]
})

/*
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},

  modules,

  plugins: [
    pathify.plugin
  ]

})
*/
